  /* background-size: 100% 100%; */
  /* width: 100%; */
  /* max-width: 100%; */
  /* height: auto; */

  /* position: relative; */
  /* text-align: center; */
.bigDiv { 
  width: 100%;
  font-size: 2.5rem;
  text-align: center;
  top: 10%;
} 
.image {
  background-image: '{props.apod}';
}
.title {
  position: relative;
  font-size: 3rem;
  color: red;
  /* text-align: center; */
  /* color: red;   */
}
/* .desc {
  position: absolute;
  /* text-align: center; */
  /* color: goldenrod; */
  /* top: 50%; */
/* } */
/* .date { */
  /* position: absolute; */ */
  /* color: fuchsia */ */
/* } */ */
/* .desc {
  position: absolute;
  color: goldenrod;
  top: 50%;
}

.title {
  position: absolute;
  text-align: center;
  color: red;
  top: 40%;
  left: 30%;
  font-size: 3rem;
}
/* .date { */

/* } */
h1 {
  color: red;
  font-size: 70px;
  text-align: center;
  margin: 2% 0 3% 0;
}
h3 {
  color: midnightblue;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
